.roadmap-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding: 2rem;
  max-width: 100%;
  background-color: #ffffff40;

  backdrop-filter: blur(3px);
}

.roadmap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.phase1-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 10px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.phase1-item-done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 10px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: #630000;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.phase2-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 10px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.phase3-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 10px;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.stage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  border: 2px solid #ffffff;
  min-width: 150px;
  max-width: 200px;
}

.stage-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(0, 0, 0);
}

.stage-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

@media (max-width: 768px) {
  .roadmap-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    background-color: #ffffff27;
    backdrop-filter: blur(3px);
    padding: 2rem;
  }

  .roadmap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    background-color: #ffffff42;
    border: 2px solid #ffffff;
    min-width: none;
  }

  .stage-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: rgb(0, 0, 0);
  }

  .stage-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0;
    padding: 0px;
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
  }

  .item-title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}
