.exchanges-container {
  margin-top: 150px;
}

.exchanges {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 35px;
  gap: 35px;
}

.exchange-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff89;
  height: 200px;
  width: 200px;
  border: solid 2px #fff;
  border-radius: 15px;
  cursor: pointer;
}

.exchange-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}

.exchange-item:hover {
  filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.823));
}

.exchange-image {
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .exchanges {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;
    gap: 35px;
  }
}
