.tokenomics-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.tokenomics-container2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  background-color: #ffffff28;
  backdrop-filter: blur(3px);
  gap: 100px;
}

.tokenomics-container2 img {
  width: 500px;
  height: 100%;
}

.tokenomics-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.tokenomics-section h2 {
  font-size: 50px;
  margin-bottom: 10px;
  color: #000000;
}

.progress-bar {
  width: 180px;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 20px rgb(255, 255, 255));
}

.burn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 20px solid white;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.progress {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 90px, 360px, 0);
  position: absolute;
  background-color: transparent;
  border: 18px solid rgb(0, 0, 0);
}

.progress:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 18px solid #f0b90b;
  clip: rect(0, 90px, 360px, 90px);
  position: absolute;
}

.progress-label {
  font-size: 18px;
  font-weight: bold;
  margin-top: -10px;
}

.total-supply-label {
  margin-top: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

.tax-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tax-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}


.tax-color {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.tax-label {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.tax-amount {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.tax-percentage {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
}

.copybutton {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.copybutton input {
  background-color: rgb(255, 255, 255);
  color: #000000;
  font-weight: bold;
  height: 37.6px;
}

@media (max-width: 768px) {
  .tokenomics-container {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .tokenomics-section {
    margin-bottom: 40px;
  }

  .progress-bar {
    margin-bottom: 20px;
  }

  .tax-container {
    flex-direction: column;
  }

  .tax-item {
    margin: 20px 0;
  }

  .tax-percentage {
    font-size: 36px;
  }

  .tax-label {
    font-size: 16px;
  }

  .tax-amount {
    font-size: 16px;
  }

  .tokenomics-container2 img {
    display: none;
  width: 0px;
  height: 100%;
}
}
