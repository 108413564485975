.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  margin-top: 120px;
  max-width: 100%;
  overflow-x: hidden;
}

.circular-button {
  display: inline-block;
  height: 100px;
  width: 100px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.circular-button img {
  display: block;
  width: 100%;
  height: 100%;
}

.copybutton1 {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.copybutton1 input {
  font-weight: bold;
  height: 40.0px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 15px;
  border: 3px solid #ffffff;
  background-color: transparent;
  color: #fff;
  text-align: center;
}

.image-container {
  flex-basis: 50%;
  padding: 1rem;
}

.image-container img {
  width: 100%;
}

.text-container {
  flex-basis: 30%;
  padding: 1rem;
}

.text-container h2 {
  margin-bottom: 1rem;
  font-size: 3rem;
  color: #ffffff;
}

.text-container h1 {
  margin-bottom: 1rem;
  font-size: 3.5rem;
  color: #ffffff;
  animation: floating 2s ease-in-out infinite;
}

.text-container p {
  margin-bottom: 3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #ffffff;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.image-container,
.text-container {
  flex-basis: 100%;
}

.image-container img {
  animation: floating 2s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.button {
  position: relative;
  display: inline-block;
  margin: 10px;
}

button {
  background: transparent;
  padding: 9px;
  border: solid 3px;
  border-radius: 50px;
  border-color: #fff;
  color: #fff;
  font-weight: bold;
  position: relative;
}

button:hover {
  transition: scale 0.5s;
  scale: 1.1;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column-reverse;
    overflow-y: hidden;
  }

  .circular-button {
    display: inline-block;
    height: 50px;
    width: 50px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .text-container {
    flex-basis: 30%;
    padding: 1rem;
    width: 100%;
  }

  .image-container,
  .text-container {
    flex-basis: 100%;
  }

  .button-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}
