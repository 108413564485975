.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 30px;
  max-width: 100%;
}

.logo {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.logo-container img {
  width: 50px;
  margin-right: 10px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.swap-container {
  display: flex;
  align-items: center;
}

.logo-text {
  font-size: 2rem;
  margin-left: 1rem;
  color: #fff;
}

.swap-text {
  font-size: 2rem;
  margin-left: 1rem;
  color: #fff;
}

.logo-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  flex-grow: 1;
  margin: 0 1rem;
  margin-left: 50px;
  gap: 1rem;
}

.nav-item {
  margin: 0.5rem 0;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
}

.nav-item:hover {
  scale: 1.1;
}

.header-btn {
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
}

.mobile-nav-container {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header-container {
    flex-wrap: wrap;
  }

  .logo-container {
    margin-right: auto;
  }

  .nav-container.show-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
  }

  .nav-container {
    display: none;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }

  .nav-container.active {
    transform: translateY(0);
  }

  .header-btn {
    order: 2;
  }

  .burger-menu {
    order: 3;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2; /* add this */
  }

  .burger-menu span {
    display: block;
    width: 30px;
    height: 4px;
    margin-bottom: 5px;
    background-color: #ffffff;
    border-radius: 2px;
  }

  .burger-menu.active span:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
  }

  .burger-menu.active span:nth-child(2) {
    opacity: 0;
  }

  .burger-menu.active span:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -8px);
  }

  .logo-text {
    display: none;
  }

  .nav-item {
    margin: 0.5rem 0;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    width: 100%;
  }

  .nav-item:hover {
    scale: 1.1;
  }

  .mobile-nav-container {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 75%;
    right: 0;
    background-color: #ff88396d;
    z-index: 1;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    backdrop-filter: blur(3px);
  }

  .mobile-nav-container.active {
    display: flex;
    transform: translateX(0%);
    opacity: 1;
  }
}
