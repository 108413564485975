.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #404040;
  color: #ffffff;
  height: 80px;
  padding: 0 40px;
  max-width: 100%;
  overflow-x: hidden;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.center-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

a {
  color: #ffffff;
  margin-right: 20px;
  font-size: 14px;
  text-decoration: none;
}

p {
  color: #ffffff;
  font-size: 14px;
  margin: 0;
}

.social-icon {
  font-size: 24px;
  margin-left: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #404040;
    color: #ffffff;
    height: 80px;
    padding: 0 5px;
    max-width: 100%;
    overflow-x: hidden;
  }

  .left-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    flex-direction: column;
  }

  .center-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .right-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 20px;
  }

  .right-section a {
    margin-right: 0;
  }
}
