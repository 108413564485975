.token-tokenomics-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 70px;
  gap: 200px;
  max-width: 100%;
}

.token-tokenomics-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.token-tokenomics-section h2 {
  font-size: 50px;
  color: black;
}

.token-progress-bar {
  width: 180px;
  height: 180px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 20px rgb(255, 255, 255));
}

.token-burn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 20px solid white;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

.token-progress {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 90px, 360px, 0);
  position: absolute;
  background-color: transparent;
  border: 18px solid rgb(0, 0, 0);
}

.token-progress:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 18px solid #f0b90b;
  clip: rect(0, 90px, 360px, 90px);
  position: absolute;
}

.token-progress-label {
  font-size: 18px;
  font-weight: bold;
  margin-top: -10px;
}

.token-total-supply-label {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
}

.token-tax-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  color: #ffffff;
}

.token-tax-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.token-tax-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 30px rgb(255, 255, 255));
  animation: sliderShape 5s linear infinite;
}

.token-tax-image img {
  width: 50px;
  height: 50px;
}

.token-tax-color {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-position: center;
  background-repeat: no-repeat;
}


.token-tax-label {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.token-tax-amount {
  font-size: 18px;
  font-weight: bold;
}

.token-tax-percentage {
  font-size: 50px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .token-tokenomics-container {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .token-tokenomics-section {
    margin-bottom: 40px;
  }

  .token-progress-bar {
    margin-bottom: 20px;
  }

  .token-tax-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .token-tax-item {
    margin: 20px 0;
  }

  .token-tax-percentage {
    font-size: 36px;
  }

  .token-tax-label {
    font-size: 16px;
  }

  .token-tax-amount {
    font-size: 16px;
  }
}
