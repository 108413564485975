.community-container {
  margin: 50px 0;
  padding: 50px 0;
  margin-top: 50px;
  max-width: 100%;
  overflow-x: hidden;
}

.community-container img {
  height: 400px;
}

.community-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

h2 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 1px;
  color: rgb(255, 255, 255);
}

p {
  font-size: 18px;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
}

.com-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-icon {
  font-size: 50px;
}

@media (max-width: 768px) {
  .com-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    margin-top: 30px;
  }

  .community-container img {
  height: 200px;
}
}
